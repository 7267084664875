import {IndexRoute} from 'features/index/components/IndexRoute';
import {commonI18nNameSpaces} from 'lib/i18n/commonII18nNameSpaces';
export default IndexRoute;

import {GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
export const getStaticProps: GetStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(locale!, [...commonI18nNameSpaces])),
  },
});
