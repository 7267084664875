import {FullScreenLoader} from 'common/FullScreenLoader';
import {decodeId} from 'common/convertId';
import {NextPageWithLayout} from 'common/types';
import {useLocalStorageOrgId} from 'features/org/store';
import {pagesPath} from 'lib/$path';
import {useViewerQuery} from 'lib/gql/generated';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

/**
 * Route for '/'.
 */
export const IndexRoute: NextPageWithLayout = () => {
  const {data, error} = useViewerQuery();
  const {orgId: savedOrgId, orgDatabaseId: savedOrgDatabaseId} =
    useLocalStorageOrgId();
  const router = useRouter();
  useEffect(() => {
    if (error) {
      router.push(pagesPath.login.$url());
      return;
    }

    if (!data?.viewer) return;

    if (savedOrgId) {
      const isFound = data.viewer.organizations.find(({organization}) => {
        return organization.id === savedOrgId;
      });
      if (isFound && savedOrgDatabaseId) {
        router.replace(pagesPath.orgs._orgId(savedOrgDatabaseId).$url());
        return;
      }
    }

    // Not found the saved org.
    // Redirect to the first org.
    const firstOrg = data.viewer.organizations[0];
    if (!firstOrg) {
      // No orgs.
      // Go to the onboarding page.
      router.replace(
        pagesPath.onboarding.$url({
          query: {
            onboardingStep: 'registerOrganization',
          },
        })
      );
    } else {
      const databaseId = decodeId(firstOrg.organization.id).docId;
      router.replace(pagesPath.orgs._orgId(databaseId).$url());
    }
  }, [data, error, router, savedOrgDatabaseId, savedOrgId]);

  return <FullScreenLoader />;
};
